<template>
    <div>
    <section class="py-5">
        <div class="container">
            <div v-if="!loggedUser.id" class="row">
                <div class="col-12">
                    <p class="mb-0 mt-2" @click="$router.push({ name: 'Home'})"><i class="fas fa-arrow-left mr-2" style="margin-right: 5px; cursor: pointer;">
                    </i><b class="ml-2" style="cursor: pointer;">Home</b></p>
                </div>
            </div>
            <div id="main-section" class="row">
                <div class="col-12">
                    <h3 class="mb-0 mt-2 primary-title">How It Works</h3>
                </div>
            </div> 
            <div class="row">
                <div class="col-12">
                    <p>Welcome to GnomeVoyage.com! Our platform is designed to make tracking and sharing the adventures of your gnomes fun and easy. Whether you're starting a new gnome or you've just found one, follow the steps below to get started.</p>
                </div>
            </div>  
            <div class="row">
                <div class="col-12">
                    <h5 class="mb-0 mt-2">Starting a New Gnome</h5>
                </div>
            </div> 
            <div class="row">
                <div class="col-12">
                    <b>1. Purchase a Gnome Code or Paint Your Own Gnome Kit</b>
                    <ul>
                        <li>Visit our online store to purchase a gnome code. Each code comes with a unique QR code and a tracking link.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b>2. Register Your Gnome</b>
                    <ul>
                        <li>Log in to your GnomeVoyage account. If you don’t have an account yet, register here.</li>
                        <li>Purchase a new gnome code.</li>
                        <li>This code links your gnome to its online profile and allows finders to post.  Keep the code the gnome’s secret.</li>
                    </ul>
                </div>
            </div> 
            <div class="row">
                <div class="col-12">
                    <b>3. Set Up the Gnome Profile</b>
                    <ul>
                        <li>Once registered, your gnome will have a unique profile page. Here, you can add more details, upload additional photos, and set the gnome’s journey intentions.</li>
                    </ul>
                </div>
            </div>  
            <div class="row">
                <div class="col-12">
                    <b>4. Start the Journey</b>
                    <ul>
                        <li>Place your gnome in a location of your choice or hand it off to a friend. The adventure begins!</li>
                    </ul>
                </div>
            </div>  
            <div class="row">
                <div class="col-12">
                    <b>5. Updating the Journey</b>
                    <ul>
                        <li>If you find a Gnome Voyage gnome or your gnome has a new experience, log in to your account, go to the gnome’s profile, and click “Add Post”.</li>
                        <li>Enter the date, location, and a description of the event. You can also upload photos to accompany your update.</li>
                    </ul>
                </div>
            </div> 
            <div class="row">
                <div class="col-12">
                    <h5 class="mb-0 mt-2">Found a Gnome?</h5>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b>1. Scan the QR Code or Enter the Gnome Code</b>
                    <ul>
                        <li>If you’ve found a gnome, scan the QR code located on the gnome. This will take you directly to its profile page on GnomeVoyage.com.</li>
                        <li>Alternatively, visit GnomeVoyage.com and click “Found a Gnome?” to find its profile.</li>
                    </ul>
                </div>
            </div> 
            <div class="row">
                <div class="col-12">
                    <b>2. Log Your Discovery</b>
                    <ul>
                        <li>If you haven’t already, create an account or log in to your existing account.</li>
                        <li>Go to the gnome’s profile page and click “Add Update”.</li>
                        <li>Share your story! Enter the date, location, and a description of how you found the gnome and what you plan to do next. Don’t forget to upload photos!</li>
                    </ul>
                </div>
            </div> 
            <div class="row">
                <div class="col-12">
                    <b>3. Decide the Gnome’s Next Adventure</b>
                    <ul>
                        <li>You can choose to keep the gnome in your yard and update its journey periodically, or pass it along to someone else to continue its adventure.</li>
                        <li>If you decide to pass it along, make sure to encourage the next finder to log their update on GnomeVoyage.com.</li>
                    </ul>
                </div>
            </div> 
            <div class="row">
                <div class="col-12">
                    <h5 class="mb-0 mt-2">Tips for a Great Gnome Journey</h5>
                    <ul>
                        <li><b>Be Detailed: </b>The more information and photos you provide, the richer the gnome’s story becomes.</li>
                        <li><b>Stay Safe:</b> Place gnomes in safe, accessible locations or hand them to good people.</li>
                        <li><b>Respect Privacy:</b> Avoid posting personal information or private locations.</li>
                        <li><b>Engage with the Community:</b> Comment on other gnome journeys and share your gnome’s adventures on social media.</li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h5 class="mb-0 mt-2">Joining the GnomeVoyage Community</h5>
                    <ul>
                        <li><b>Community Forums:</b> Engage with other gnome enthusiasts on our community forums. Share tips, stories, and ask questions.</li>
                        <li><b>GnomesUncorked Events:</b> Interested in a fun night of wine and painting? Contact us to host a GnomesUncorked event at your venue.</li>
                        <li><b>Follow and Share: </b>Follow your favorite gnomes and users, and share their journeys to spread the adventure.</li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h5 class="mb-0 mt-2">Contact Us</h5>
                    <p>If you have any questions or need assistance, visit our <a style="cursor: pointer" @click="$router.push({ name: 'ContactUsPage'})"><b><u>Contact Us</u></b></a> page and reach out to our support team. We’re here to help you make the most out of your GnomeVoyage experience.</p>
                </div>
            </div>
            <div id="faq-section" ref="faqSection" class="row">
                <div class="col-12">
                    <h3 class="mb-0 mt-2 primary-title">Frequently Asked Questions (FAQ)</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h5 class="mb-0 mt-2">Getting Started</h5>
                </div>
            </div> 
            <div class="row">
                <div class="col-12">
                    <b>1. What is GnomeVoyage.com?</b>
                    <p>GnomeVoyage.com is a unique platform that allows you to track and share the journeys of gnomes using QR codes. It offers a community-driven experience where you can follow the adventures of these gnomes, add your own stories, and connect with others.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b>2. How do I create an account?</b>
                    <p>To create an account, click on the "Register" button on the login screen. Fill in your email, username, and password, and then click "Register". You will receive a confirmation email to verify your account.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b>3. How do I log in?</b>
                    <p>Go to the login screen and enter your email/username and password. Click "Log In" to access your account.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b>4. What should I do if I forget my password?</b>
                    <p>Click on the "Forgot Password" link on the login screen. Enter your email address, and you will receive instructions to reset your password.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h5>Using the Platform</h5>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b>5. How do I register my own gnome?</b>
                    <p>To register a gnome, you need to purchase a gnome code from our online store. Once you have the code, log in to your user dashboard and click on "Add New Gnome". Fill in the gnome details, upload a photo, and enter the gnome code. Your gnome is now registered and ready to begin its journey</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b>6. How do I update my gnome’s journey?</b>
                    <p>Go to the gnome’s profile page and click on "Add Update". Enter the date, location, and a description of the update. You can also upload photos to accompany your story.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b>7. How can I track a gnome’s journey?</b>
                    <p>Each gnome has a unique profile page where you can see its journey log, including all the updates added by various users. You can also follow gnomes to receive notifications of new updates.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b>8. Can I share my gnome’s journey on social media?</b>
                    <p>Yes, you can share your gnome’s journey on social media. On the gnome’s profile page, click the "Share" button to post updates to platforms like Facebook, Twitter, and Instagram.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h5>Ownership and Risk</h5>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b>9. What happens when I buy a gnome?</b>
                    <p>When you buy a gnome, it is yours. You can choose to keep it in your yard, share its adventures with a small circle, or let it embark on a journey for others to find.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b>10. What if I give my gnome to someone else or leave it out for others to find?</b>
                    <p>If you give your gnome to others or leave it out for others to find, there is a risk that it might never make it back to you. Someone else might take it, or it could get lost forever. This is part of the adventure and risk of GnomeVoyage.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b>11. How do I ensure the security of my gnome code?</b>
                    <p>Guard your gnome code carefully! Anybody with the code can post as the gnome. If you want to maintain control over the gnome’s journey, keep the code secure and share it only with trusted individuals.  If a gnome’s code is abused, it can be reset.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b>12. Can I update the gnome's journey myself?</b>
                    <p>Absolutely! You can always keep the gnome in your yard and update its journey on social media yourself or share it among a small circle of friends and family.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h5>Community and Interaction</h5>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b>13. How do I participate in the community forums?</b>
                    <p>Navigate to any gnome’s social page from the main menu. Here, you can view various discussion threads, reply to posts, and create new topics</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b>14. How can I follow other users or gnomes?</b>
                    <p>You can follow other users or gnomes by visiting their profile pages and clicking the "Like" button. This allows you to stay updated on their activities and journey updates with updates on your user page.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b>15. How do I report inappropriate content?</b>
                    <p>If you encounter inappropriate content, click the "Report" button next to the content. Our moderators will review the report and take appropriate action.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h5>Purchasing and E-Commerce</h5>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b>16. How do I buy gnome codes or kits?</b>
                    <p>Visit our online store to browse available gnome codes and kits. Add your selected items to the shopping cart, proceed to checkout, and complete the payment process.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b>17. What payment methods do you accept?</b>
                    <p>We accept major credit cards, PayPal, and other secure payment methods as indicated during checkout.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b>18. Can I gift a gnome code to someone?</b>
                    <p>Yes, you can purchase gnome codes as gifts. During checkout, provide the recipient’s email address, and they will receive instructions on how to claim their gnome.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h5>Educational and Non-Profit Programs</h5>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b>19. How can schools or non-profits partner with GnomeVoyage.com?</b>
                    <p>We offer special programs for schools and non-profits. Contact us through the "Contact Us" page to learn more about partnership opportunities and discounts.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b>20. How can GnomeVoyage.com be used in educational settings?</b>
                    <p>Our platform can be used to create engaging and educational experiences in various subjects, including art, geography, social studies, and more. We provide lesson plans and resources to help integrate our platform into the classroom.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h5>GnomesUncorked Events</h5>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b>21. What are GnomesUncorked events?</b>
                    <p>GnomesUncorked is a fun and creative event where participants can enjoy a wine and paint night, painting their own gnome while sipping their favorite wine or other adult beverage. These events can be hosted at your bar or other venues.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b>22. How can I host a GnomesUncorked event?</b>
                    <p>If you are interested in hosting a GnomesUncorked event at your bar or venue, please contact us through the "Contact Us" page. We will provide all the necessary details and help you organize a successful and enjoyable event.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h5>Account and Privacy</h5>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b>23. How do I edit my profile information?</b>
                    <p>Log in to your account and navigate to your user dashboard. Click on "Account Settings" to update your profile information, change your password, and manage other account details.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b>24. What is your privacy policy?</b>
                    <p>Our privacy policy explains how we collect, use, and protect your personal information. You can read the full policy on our "Privacy Policy" page.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b>25. How do I delete my account?</b>
                    <p>If you wish to delete your account, please contact our support team through the "Contact Us" page. They will guide you through the account deletion process.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h5>Support and Feedback</h5>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b>26. How can I contact support?</b>
                    <p>For support, visit our "Contact Us" page and fill out the contact form. Our support team will get back to you as soon as possible.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b>27. How can I provide feedback or suggest new features?</b>
                    <p>We welcome your feedback and suggestions! Use the contact form on the "Contact Us" page to share your thoughts. Your input helps us improve our platform.</p>
                </div>
            </div>
        </div>
    </section>
    <footer class="footer py-5">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 mb-5 mb-lg-0">
                    <h6 class="text-uppercase mb-2">Gnome Voyage</h6>
                    <p class="mb-4 pb-2">Fun, Adventure, Gnomes</p>
                    <a href="javascript:;" class="text-secondary me-xl-4 me-3">
                        <span class="text-lg fab fa-facebook" aria-hidden="true"></span>
                    </a>
                    <a href="javascript:;" class="text-secondary me-xl-4 me-3">
                        <span class="text-lg fab fa-twitter" aria-hidden="true"></span>
                    </a>
                    <a href="javascript:;" class="text-secondary me-xl-4 me-3">
                        <span class="text-lg fab fa-instagram" aria-hidden="true"></span>
                    </a>
                    <a href="javascript:;" class="text-secondary me-xl-4 me-3">
                        <span class="text-lg fab fa-pinterest" aria-hidden="true"></span>
                    </a>
                    <a href="javascript:;" class="text-secondary me-xl-4 me-3">
                        <span class="text-lg fab fa-github" aria-hidden="true"></span>
                    </a>
                </div>
                <div class="col-md-2 col-6 ms-lg-auto mb-md-0 mb-4">
                    <h6 class="text-sm">Company</h6>
                    <ul class="flex-column ms-n3 nav">
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'AboutUs'})">
                                About Us
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'Press'})">
                                Press
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'ContactUsPage'})">Contact Us</a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-2 col-6 mb-md-0 mb-4">
                    <h6 class="text-sm">Pages</h6>
                    <ul class="flex-column ms-n3 nav">
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'GnomeList'})">Browse Gnomes</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'SignIn'})">Login/Register</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'ProfileInfo'})">My Profile</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'HomePage'})">E-Commerce</a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-2 col-6 mb-md-0 mb-4">
                    <h6 class="text-sm">Legal</h6>
                    <ul class="flex-column ms-n3 nav">
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'TermsOfUse'})">Terms of Use</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'AboutUs'})">
                                About Us
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'ContactUsPage'})">Contact Us</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'PrivacyPolicy'})">
                                Privacy
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-2 col-6 mb-md-0 mb-4">
                    <h6 class="text-sm">Resources</h6>
                    <ul class="flex-column ms-n3 nav">
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'HowItWorks'})">Learn More</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'HomePage'})">Gnome Codes</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'HomePage'})">Gnome Kits</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'GnomeVerificationPage'})">FOUND A GNOME?</a>
                        </li>
                    </ul>
                </div>
            </div>
            <hr class="horizontal dark mt-lg-5 mt-4 mb-sm-4 mb-1">
            <div class="row">
                <div class="col-8 mx-lg-auto text-lg-center">
                    <p class="text-sm text-secondary">Copyright © 2024 GnomeVoyage.com a part of Orange Pony Tech LLC</p>
                </div>
            </div>
        </div>
    </footer>
    </div>
  </template>
  
  <script>
  import utils from '../../utils';
  export default {
    name: "Home",
    components: {
    },
    data () {
        return {
            loggedUser: {}
        }
    },
    watch: {
    '$route.query.section': {
            immediate: true,
            handler() {
            this.scrollToFAQ();
            }
        }
    },
    mounted() {
        this.loggedUser = JSON.parse(utils.getLoggedUser() || '{}');
        
        this.$store.state.showSidenav = !!this.loggedUser.id;
        this.$store.state.showNavbar = !!this.loggedUser.id;
        this.scrollToFAQ()
    },
    methods: {
        scrollToFAQ() {
            if (this.$route.query.section === 'faq') {
                const faqSection = document.getElementById("faq-section");
                if (faqSection) {
                    faqSection.scrollIntoView({ behavior: "smooth" });
                }
            } else if (this.$route.query.section === 'main') {
                const faqSection = document.getElementById("main-section");
                if (faqSection) {
                    faqSection.scrollIntoView({ behavior: "smooth" });
                }
            }
            
        }
    }
  };
  </script>
  <style scoped>
  .primary-title {
    color: #0fdf14;
  }

  .primary-button {
    background-color: #0fdf14;
  }

  .primary-button:hover {
    background-color: transparent;
    color: #67748e;
  }

  .margin-right-5 {
    margin-right: 5px;
  }

  .primary-link {
    color: #0fdf14 !important;
  }
</style>