import { createRouter, createWebHistory } from "vue-router";
import Default from "../views/dashboards/Default.vue";
import Automotive from "../views/dashboards/Automotive.vue";
import SmartHome from "../views/dashboards/SmartHome.vue";
import VRDefault from "../views/dashboards/vr/VRDefault.vue";
import VRInfo from "../views/dashboards/vr/VRInfo.vue";
import CRM from "../views/dashboards/CRM.vue";
import Overview from "../views/pages/profile/Overview.vue";
import ProfileInfo from "../views/pages/profile/ProfileInfo.vue";
import UserProfileInfo from "../views/pages/Users/UserProfileInfo.vue";
import RegisterAddressPage from "../views/pages/Users/RegisterAddressPage.vue";
import EditAddressPage from "../views/pages/Users/EditAddressPage.vue";
import UsersListPage from "../views/pages/Users/UsersListPage.vue";
import Teams from "../views/pages/profile/Teams.vue";
import Projects from "../views/pages/profile/Projects.vue";
import General from "../views/pages/projects/General.vue";
import Timeline from "../views/pages/projects/Timeline.vue";
import NewProject from "../views/pages/projects/NewProject.vue";
import Pricing from "../views/pages/Pricing.vue";
import RTL from "../views/pages/Rtl.vue";
import Charts from "../views/pages/Charts.vue";
import SweetAlerts from "../views/pages/SweetAlerts.vue";
import Notifications from "../views/pages/Notifications.vue";
import NotificationsPage from "../views/notifications/Notifivations.vue";
import Kanban from "../views/applications/Kanban.vue";
import Wizard from "../views/applications/wizard/Wizard.vue";
import DataTables from "../views/applications/DataTables.vue";
import Calendar from "../views/applications/Calendar.vue";
import Analytics from "../views/applications/analytics/Analytics.vue";
import EcommerceOverview from "../views/ecommerce/overview/Overview.vue";
import NewProduct from "../views/ecommerce/products/NewProduct.vue";
import EditProduct from "../views/ecommerce/EditProduct.vue";
import ProductPage from "../views/ecommerce/ProductPage.vue";
import PublicProductPage from "../views/ecommerce/PublicProductPage.vue";
import PublicHomePage from "../views/ecommerce/PublicHomePage.vue";
import ProductsList from "../views/ecommerce/ProductsList.vue";
import HomePage from "../views/ecommerce/HomePage.vue";
import CartPage from "../views/ecommerce/CartPage.vue";
import PublicCartPage from "../views/ecommerce/PublicCartPage.vue";
import OrderDetails from "../views/ecommerce/Orders/OrderDetails";
import OrderList from "../views/ecommerce/Orders/OrderList";
import ShippingPage from "../views/ecommerce/shipping/ShippingPage.vue";
import ShippingSummeryPage from "../views/ecommerce/shipping/ShippingSummeryPage.vue";
import Referral from "../views/ecommerce/Referral";
import Reports from "../views/pages/Users/Reports.vue";
import NewUser from "../views/pages/Users/NewUser.vue";
import Settings from "../views/pages/Account/Settings.vue";
import Billing from "../views/pages/Account/Billing.vue";
import Invoice from "../views/pages/Account/Invoice.vue";
import Security from "../views/pages/Account/Security.vue";
import Widgets from "../views/pages/Widgets.vue";
import Basic from "../views/auth/signin/Basic.vue";
import SignIn from "../views/auth/SignIn.vue";
import SignUp from "../views/auth/SignUp.vue";
import ResetPasswordMailForm from "../views/auth/ResetPasswordMailForm.vue";
import ResetPasswordMail from "../views/auth/ResetPasswordForm.vue";
import Cover from "../views/auth/signin/Cover.vue";
import Illustration from "../views/auth/signin/Illustration.vue";
import ResetBasic from "../views/auth/reset/Basic.vue";
import ResetCover from "../views/auth/reset/Cover.vue";
import ResetIllustration from "../views/auth/reset/Illustration.vue";
import VerificationBasic from "../views/auth/verification/Basic.vue";
import VerificationCover from "../views/auth/verification/Cover.vue";
import VerificationIllustration from "../views/auth/verification/Illustration.vue";
import SignupBasic from "../views/auth/signup/Basic.vue";
import SignupCover from "../views/auth/signup/Cover.vue";
import SignupIllustration from "../views/auth/signup/Illustration.vue";
import Error404 from "../views/auth/error/Error404.vue";
import Error500 from "../views/auth/error/Error500.vue";
import lockBasic from "../views/auth/lock/Basic.vue";
import lockCover from "../views/auth/lock/Cover.vue";
import lockIllustration from "../views/auth/lock/Illustration.vue";
import GnomeRegistrationPage from "../views/pages/gnome/GnomeRegistrationPage.vue"
import GnomeOverviewPage from "../views/pages/gnome/GnomeOverviewPage.vue";
import GnomeListPage from "../views/pages/gnome/GnomeListPage.vue";
import GnomeAuthenticationPage from "../views/pages/gnome/GnomeAuthenticationPage.vue";
import GnomeSocialPage from "../views/pages/posting/GnomeSocialPage.vue";
import GnomePostViewPage from "../views/pages/posting/GnomePostViewPage.vue";
import GnomeCommentActivityPostPage from "../views/pages/posting/GnomeCommentActivityPostPage.vue";
import GnomeFlaggedPostListPage from "../views/pages/posting/GnomeFlaggedPostListPage.vue";
import GnomeFlaggedPostViewPage from "../views/pages/posting/GnomeFlaggedPostViewPage.vue";
import StripePage from "../views/pages/payment/StripePage.vue";
import ContactUsPage from "../views/contacts/ContactUsPage.vue";
import ActivityLogPage from "../views/pages/activity/AvtivityLogPage.vue";
import LikeActivityPage from "../views/pages/activity/LikeActivityPage.vue";
import CommentActivityPage from "../views/pages/activity/CommentActivityPage.vue";
import SocialAuthenticationLoadingPage from "../views/auth/SocialAuthenticationLoadingPage";
import ShowMap from "../views/pages/Map/ShowMap.vue";
import UserEarningsPage from '../views/earnings/HomePage.vue'
import PointsCriteria from '../views/earnings/PointsCriteria.vue'
import CustomAdsRegistration from "../views/pages/customAds/CustomAdsRegistration.vue";
import CustomAdsList from "../views/pages/customAds/CustomAdsList.vue";
import CustomAdsEdit from "../views/pages/customAds/CustomAdsEdit.vue";
import Home from "../views/landing/Home.vue";
import PrivacyPolicy from "../views/landing/PrivacyPolicy.vue";
import TermsOfUse from '../views/landing/TermsOfUse.vue';
import AboutUs from '../views/landing/AboutUs.vue';
import Press from '../views/landing/Press.vue'
import HowItWorks from '../views/landing/HowItWorks.vue'

import utils from "../utils";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      authRequired: false
    }
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
    meta: {
      authRequired: false
    }
  },
  {
    path: "/how-it-works",
    name: "HowItWorks",
    component: HowItWorks,
    meta: {
      authRequired: false
    }
  },
  {
    path: "/terms-and-conditions",
    name: "TermsOfUse",
    component: TermsOfUse,
    meta: {
      authRequired: false
    }
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: AboutUs,
    meta: {
      authRequired: false
    }
  },
  {
    path: "/press",
    name: "Press",
    component: Press,
    meta: {
      authRequired: false
    }
  },
  {
    path: "/dashboards/dashboard-default",
    name: "Default",
    component: Default,
    meta: {
      authRequired: true
    }
  },
  
  {
    path: "/dashboards/automotive",
    name: "Automotive",
    component: Automotive,
  },
  {
    path: "/dashboards/smart-home",
    name: "Smart Home",
    component: SmartHome,
  },
  {
    path: "/dashboards/vr/vr-default",
    name: "VR Default",
    component: VRDefault,
  },
  {
    path: "/dashboards/vr/vr-info",
    name: "VR Info",
    component: VRInfo,
  },
  {
    path: "/dashboards/crm",
    name: "CRM",
    component: CRM,
  },
  {
    path: "/pages/profile/overview",
    name: "Profile Overview",
    component: Overview,
  },
  {
    path: "/pages/profile/teams",
    name: "Teams",
    component: Teams,
  },
  {
    path: "/pages/profile/projects",
    name: "All Projects",
    component: Projects,
  },
  {
    path: "/pages/projects/general",
    name: "General",
    component: General,
  },
  {
    path: "/pages/projects/timeline",
    name: "Timeline",
    component: Timeline,
  },
  {
    path: "/pages/projects/new-project",
    name: "New Project",
    component: NewProject,
  },
  {
    path: "/pages/pricing-page",
    name: "Pricing Page",
    component: Pricing,
  },
  {
    path: "/pages/rtl-page",
    name: "RTL",
    component: RTL,
  },
  {
    path: "/pages/charts",
    name: "Charts",
    component: Charts,
  },
  {
    path: "/pages/widgets",
    name: "Widgets",
    component: Widgets,
  },
  {
    path: "/pages/sweet-alerts",
    name: "Sweet Alerts",
    component: SweetAlerts,
  },
  {
    path: "/pages/notifications",
    name: "Notifications",
    component: Notifications,
  },
  {
    path: "/notifications",
    name: "NotificationsPage",
    component: NotificationsPage,
  },
  {
    path: "/applications/kanban",
    name: "Kanban",
    component: Kanban,
  },
  {
    path: "/applications/wizard",
    name: "Wizard",
    component: Wizard,
  },
  {
    path: "/applications/data-tables",
    name: "Data Tables",
    component: DataTables,
  },
  {
    path: "/applications/calendar",
    name: "Calendar",
    component: Calendar,
  },
  {
    path: "/applications/analytics",
    name: "Analytics",
    component: Analytics,
  },
  {
    path: "/ecommerce/overview",
    name: "Overview",
    component: EcommerceOverview,
  },
  {
    path: "/ecommerce/products/new-product",
    name: "New Product",
    component: NewProduct,
  },
  {
    path: "/ecommerce/products/edit-product/:id",
    name: "Edit Product",
    component: EditProduct,
  },
  {
    path: "/ecommerce/products/product-page/:id",
    name: "Product Page",
    component: ProductPage,
  },
  {
    path: "/ecommerce/products/products-list",
    name: "Products List",
    component: ProductsList,
  },
  {
    path: "/ecommerce/home",
    name: "HomePage",
    component: HomePage,
    meta: {
      authRequired: true
    }
  },
  {
    path: "/ecommerce/public-home",
    name: "PublicHomePage",
    component: PublicHomePage,
  },
  {
    path: "/ecommerce/products/public-product/:id",
    name: "PublicProductPage",
    component: PublicProductPage,
  },
  {
    path: "/ecommerce/public-cart",
    name: "PublicCartPage",
    component: PublicCartPage,
  },
  {
    path: "/ecommerce/cart",
    name: "CartPage",
    component: CartPage,
  },
  {
    path: "/ecommerce/Orders/order-details/:id",
    name: "Order Details",
    component: OrderDetails,
  },
  {
    path: "/ecommerce/Orders/order-list",
    name: "Order List",
    component: OrderList,
  },
  {
    path: "/ecommerce/shipping-detail",
    name: "ShippingPage",
    component: ShippingPage,
  },
  {
    path: "/ecommerce/shipping-summery",
    name: "ShippingSummeryPage",
    component: ShippingSummeryPage,
  },
  {
    path: "/ecommerce/referral",
    name: "Referral",
    component: Referral,
  },
  {
    path: "/pages/users/reports",
    name: "Reports",
    component: Reports,
  },
  {
    path: "/pages/users/new-user",
    name: "New User",
    component: NewUser,
  },
  {
    path: "/pages/account/settings",
    name: "Settings",
    component: Settings,
  },
  {
    path: "/pages/account/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/pages/account/invoice",
    name: "Invoice",
    component: Invoice,
  },
  {
    path: "/pages/account/Security",
    name: "Security",
    component: Security,
  },
  {
    path: "/authentication/signin/basic",
    name: "Signin Basic",
    component: Basic,
  },
  {
    path: "/sign_in",
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/authentication/signin/cover",
    name: "Signin Cover",
    component: Cover,
  },
  {
    path: "/authentication/signin/illustration",
    name: "Signin Illustration",
    component: Illustration,
  },
  {
    path: "/authentication/reset/basic",
    name: "Reset Basic",
    component: ResetBasic,
  },
  {
    path: "/auth/reset_password",
    name: "ResetPasswordMail",
    component: ResetPasswordMailForm,
  },
  {
    path: "/auth/password/reset",
    name: "ResetPassword",
    component: ResetPasswordMail,
  },
  {
    path: "/authentication/reset/cover",
    name: "Reset Cover",
    component: ResetCover,
  },
  {
    path: "/authentication/reset/illustration",
    name: "Reset Illustration",
    component: ResetIllustration,
  },
  {
    path: "/authentication/lock/basic",
    name: "Lock Basic",
    component: lockBasic,
  },
  {
    path: "/authentication/lock/cover",
    name: "Lock Cover",
    component: lockCover,
  },
  {
    path: "/authentication/lock/illustration",
    name: "Lock Illustration",
    component: lockIllustration,
  },
  {
    path: "/authentication/verification/basic",
    name: "Verification Basic",
    component: VerificationBasic,
  },
  {
    path: "/authentication/verification/cover",
    name: "Verification Cover",
    component: VerificationCover,
  },
  {
    path: "/authentication/verification/illustration",
    name: "Verification Illustration",
    component: VerificationIllustration,
  },
  {
    path: "/authentication/signup/basic",
    name: "Signup Basic",
    component: SignupBasic,
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUp,
  },
  {
    path: "/authentication/signup/cover",
    name: "Signup Cover",
    component: SignupCover,
  },
  {
    path: "/authentication/signup/illustration",
    name: "Signup Illustration",
    component: SignupIllustration,
  },
  {
    path: "/authentication/error/error404",
    name: "Error Error404",
    component: Error404,
  },
  {
    path: "/authentication/error/error500",
    name: "Error Error500",
    component: Error500,
  },
  {
    path: "/profile-info",
    name: "ProfileInfo",
    component: ProfileInfo,
    meta: {
      authRequired: true
    }
  },
  {
    path: "/users/profile-info/:id",
    name: "UserProfileInfo",
    component: UserProfileInfo,
  },
  {
    path: "/users/register-address",
    name: "RegisterAddressPage",
    component: RegisterAddressPage,
  },
  {
    path: "/users/edit-address",
    name: "EditAddressPage",
    component: EditAddressPage,
  },
  {
    path: "/users/list",
    name: "UsersListPage",
    component: UsersListPage,
  },
  {
    path: "/gnome/registration",
    name: "GnomeRegistration",
    component: GnomeRegistrationPage,
  },
  {
    path: "/user-earnings",
    name: "UserEarnings",
    component: UserEarningsPage,
  },
  {
    path: "/user-earnings/:id",
    name: "UserEarningsByAdmin",
    component: UserEarningsPage,
  },
  {
    path: "/user-earnings/criteria",
    name: "UserEarningsCriteria",
    component: PointsCriteria,
  },
  {
    path: "/show/map",
    name: "ShowMap",
    component: ShowMap,
    props: (route) => ({
      lat: route.query.lat || 'defaultLatitude',
      lng: route.query.lng || 'defaultLongitude',
      userId: route.query.userId || null,
      postId: route.query.postId || null
    })
  },
  {
    path: "/gnome/overview/:id",
    name: "GnomeOverview",
    component: GnomeOverviewPage,
    props: true
  },
  {
    path: "/gnome/list",
    name: "GnomeList",
    component: GnomeListPage,
    meta: {
      authRequired: true
    }
  },
  {
    path: "/gnome/gnome-authentication/:id",
    name: "GnomeAuthenticationPage",
    component: GnomeAuthenticationPage,
  },
  {
    path: "/gnome-social/home/:id",
    name: "GnomeSocialPage",
    component: GnomeSocialPage
    // beforeEnter: (to, from, next) => {
    //   const token = localStorage.getItem('gnomeToken');

    //   if (!token) {
    //     const id = to.params.id;
    //     next(`/gnome/gnome-authentication/${id}`);
    //   } else {
    //     next();
    //   }
    // }
  },
  {
    path: "/gnome-social/post-view/:id",
    name: "GnomePostViewPage",
    component: GnomePostViewPage,
  },
  {
    path: "/activity-log/comment-activity-post/:id",
    name: "GnomeCommentActivityPostPage",
    component: GnomeCommentActivityPostPage,
  },
  {
    path: "/gnome-verification",
    name: "GnomeVerificationPage",
    component: GnomeAuthenticationPage,
    meta: {
      authRequired: true
    }
  },
  {
    path: "/gnome-social/post-flagged-list",
    name: "GnomeFlaggedPostListPage",
    component: GnomeFlaggedPostListPage,
  },
  {
    path: "/gnome-social/flagged-post-view/:id",
    name: "GnomeFlaggedPostViewPage",
    component: GnomeFlaggedPostViewPage,
  },
  {
    path: "/payment",
    name: "StripePage",
    component: StripePage,
    props: true
  },
  {
    path: "/auth/social-media/callback",
    name: "SocialAuthenticationLoadingPage",
    component: SocialAuthenticationLoadingPage
  },
  {
    path: "/contact-us",
    name: "ContactUsPage",
    component: ContactUsPage
  },
  {
    path: "/activity-log",
    name: "ActivityLogPage",
    component: ActivityLogPage
  },
  {
    path: "/activity-log/like-activities/:id",
    name: "LikeActivityPage",
    component: LikeActivityPage
  },
  {
    path: "/activity-log/comment-activities/:id",
    name: "CommentActivityPage",
    component: CommentActivityPage
  },
  {
    path: "/custom-ads/custom-ads-regitration",
    name: "CustomAdsRegistration",
    component: CustomAdsRegistration
  },
  {
    path: "/custom-ads/custom-ads-list",
    name: "CustomAdsList",
    component: CustomAdsList
  }
  ,
  {
    path: "/custom-ads/custom-ads-edit/:id",
    name: "CustomAdsEdit",
    component: CustomAdsEdit
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  const loggedIn = utils.isLoggedIn();
  // If authentication is required and the user is logged in, allow access
  if (to.meta.authRequired && loggedIn) {
    return next();
  } 

  // If authentication is required and the user is NOT logged in, redirect to the SignIn page
  else if (to.meta.authRequired && !loggedIn) {
    console.log('Redirecting to SignIn with intended route:', to.fullPath);
    return next({ name: 'SignIn', query: { redirect: to.fullPath } });
  } 

  // If a route should redirect authenticated users (e.g., "SignIn"), go to default page
  else if (to.meta.authRedirect && loggedIn) {
    return next({ name: 'Default' });
  }

  return next();
});

/**
 * After each route update
 */
router.afterEach((/*to, from*/) => {
})

export default router;
