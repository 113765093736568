<template>
    <div class="container-fluid">
      <div
        class="mt-4 page-header min-height-300 border-radius-xl"
        :style="{
          backgroundImage:
            'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
          backgroundPositionY: '50%',
        }"
      >
        <span class="mask bg-gradient-success opacity-6"></span>
      </div>
      <div class="d-flex justify-content-center">
        <div class="p-4 col-lg-6">
          <label>Category</label>
          <select
            v-model="filterCategory"
            class="form-control"
            name="filterCategory"
            @change="filterTable()"
          >
            <option value="0" selected>{{ '--None--' }}</option>
            <option v-for="(category) in categories" :key="category.id" :value="category.id">{{ category.category }}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="pb-0 card-header">
              <div class="d-lg-flex">
                <div>
                  <h5 class="mb-0">All Products</h5>
                  <p class="mb-0 text-sm">
                    Now you can check all gnome, code, and tags products.
                  </p>
                </div>
              </div>
            </div>
            <div v-if="gnomeProductList.length > 0" class="px-0 pb-0 card-body">
              <div class="row">
                <public-product-item-card
                  v-for="gnomeProduct in gnomeProductList"
                  :key="gnomeProduct.id"
                  :product="gnomeProduct"
                  :action="{
                    color: 'success',
                    label: 'More Info',
                    data: {
                        id: gnomeProduct.id
                    }
                  }"
                />
              </div>
            </div>
            <div v-else class="text-center align-middle norecodsfound">
              <h2>{{ 'No records found...!' }}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import setTooltip from "@/assets/js/tooltip.js";
  import setNavPills from "@/assets/js/nav-pills.js";
  import GnomeProductService from "../../services/api/gnome/GnomeProductService";
  
  import GnomeDefaultDataMixin from "../../mixins/GnomeDefaultDataMixin";
  import PublicProductItemCard from "../ecommerce/components/PublicProductItemCard.vue"
  
  export default {
    name: "ProductsList",
    components: {
      PublicProductItemCard
    },
    mixins:[GnomeDefaultDataMixin],
    data () {
      return {
        gnomeProductList: [],
        filterCategory: 0
      }
    },
    async mounted() {
      this.$store.state.isAbsolute = true;
      this.$store.state.showSidenav = false
      setNavPills();
  
      setTooltip(this.$store.state.bootstrap);
      await this.getProductCategories()
      await this.getGnomeProductList('home')
    },
    beforeUnmount() {
      this.$store.state.isAbsolute = false;
      this.$store.state.showSidenav = true
    },
    methods: {
      async getGnomeProductList (page, categoryId) {
        try {
          this.$swal.showLoading();
          this.gnomeProductList = []
          const productList = []
  
          const gnomeProductService = new GnomeProductService()
          const response = await gnomeProductService.getGnomeProductList(page, categoryId)
  
          response.data.data.forEach((product) => {
  
            const obj = {
              id: product.id,
              name : product.name,
              description: product.description || '',
              categoryId: product.product_category.id,
              categoryName: product.product_category.name,
              productImageId: product.images.length > 0 ? product.images[0].id : 0,
              productImagePath: product.images.length > 0 ? product.images[0].image : '',
              currencyId: product.currency_type.id,
              currencyName: product.currency_type.name,
              price: product.price,
              qty: product.quantity,
              userId: product.user.id,
              userName: product.user.first_name + ' ' + product.user.last_name,
              status: product.status,
              createdAt: product.created_at
            }
            productList.push(obj)
          })
  
          this.gnomeProductList = productList
  
          this.$swal.close();
        } catch (error) {
          console.log('Gnome product getting Error: ', error)
          this.$swal.close()
          this.$swal({
            text: "Something went wrong...!",
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
        }
      },
      async filterTable () {
        await this.getGnomeProductList('home', this.filterCategory)
      }
    }
  };
  </script>
  <style scoped>
  .overflow-x-clip{
      overflow-x: clip!important;
  }
  
  .norecodsfound {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn-padding{
    padding: 0.75rem;
  }
  </style>