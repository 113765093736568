<template>
  <aside
    id="sidenav-main"
    class="my-3 overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-xl"
    :class="isRTL ? 'me-3 rotate-caret' : 'ms-3'"
  >
  <div class="sidenav-header position-relative">
    <i
      id="iconSidenav"
      class="top-0 end-0 p-3 cursor-pointer fas fa-times text-secondary position-absolute d-lg-none"
      aria-hidden="true"
      @click="navbarMinimize"
    ></i>
    <router-link class="m-0 navbar-brand" to="/">
      <transition name="fade">
      <img :key="currentLogo" :src="currentLogo" class="navbar-brand-img h-100" alt="main_logo" />
        </transition>
    </router-link>
  </div>

    <hr class="mt-0 horizontal dark" />
    <sidenav-list :card-bg="customClass" />
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";
import mainLogo from "@/assets/img/gnome-main-logo.png";
import secLogo from "@/assets/img/gnome-sec-logo.png";
import { mapMutations } from "vuex";
import { mapState } from "vuex";
export default {
  name: "Index",
  components: {
    SidenavList,
  },
  props: {
    customClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      logos: [
        mainLogo,
        secLogo
      ],
      currentLogoIndex: 0,  // Index to track the current logo
      intervalId: null,
    };
  },
  computed: {
    ...mapState(["isRTL"]),
    currentLogo() {
      return this.logos[this.currentLogoIndex];
    }
  },
  mounted() {
    this.startLogoRotation();
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  },
  methods: {
    ...mapMutations(["navbarMinimize"]),
    
    startLogoRotation() {
      this.intervalId = setInterval(() => {
        this.currentLogoIndex = (this.currentLogoIndex + 1) % this.logos.length;
      }, 5000);
    },
  }
}
</script>
<style scoped>
/* CSS for fade effect */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter {
  opacity: 0;
}
.fade-leave-to {
  opacity: 0;
}
</style>
