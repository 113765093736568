<template>
  <nav
    id="navbarBlur"
    class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl"
    :class="[isRTL ? 'top-1 position-sticky z-index-sticky' : '']"
    v-bind="$attrs"
    data-scroll="true"
  >
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs
        :current-directory="currentDirectory"
        :current-page="currentRouteName"
        :text-white="textWhite"
      />
      <div
        class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none"
        :class="isRTL ? 'me-3' : ''"
      >
        <a href="#" class="p-0 nav-link text-body" @click.prevent="minNav">
          <div class="sidenav-toggler-inner">
            <i
              class="sidenav-toggler-line"
              :class="textWhite ? 'bg-white' : ''"
            ></i>
            <i
              class="sidenav-toggler-line"
              :class="textWhite ? 'bg-white' : ''"
            ></i>
            <i
              class="sidenav-toggler-line"
              :class="textWhite ? 'bg-white' : ''"
            ></i>
          </div>
        </a>
      </div>
      <div
        id="navbar"
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="isRTL ? 'px-0' : 'me-sm-4'"
      >
        <div
          class="pe-md-3 d-flex align-items-center"
          :class="isRTL ? 'me-md-auto' : 'ms-md-auto'"
        >
        </div>
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center px-4">
            <a
              id="iconNavbarSidenav"
              href="#"
              class="p-0 nav-link text-body"
              @click="toggleSidebar"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </a>
          </li>
          <li class="px-3 nav-item d-flex align-items-center">
            <a
              class="p-0 nav-link"
              :class="textWhite ? textWhite : 'text-body'"
              @click="redirect(isLoggedIn() ? 'ProfileInfo' : 'SignIn')"
            >
              <i class="cursor-pointer fa fa-user fixed-plugin-button-nav fa-lg"></i>
            </a>
          </li>
          <li
            v-if="isLoggedIn()"
            class="nav-item dropdown d-flex align-items-center"
            :class="isRTL ? 'ps-2 px-3' : 'pe-2 px-3'"
          >
            <a
              id="dropdownMenuButtonNotifications"
              href="#"
              class="p-0 nav-link position-relative"
              :class="[
                textWhite ? textWhite : 'text-body',
                showMenuNotification ? 'show' : '',
              ]"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              @click="showMenuNotification = !showMenuNotification"
            >
              <i class="cursor-pointer fa fa-bell fa-lg"></i>
              <span class="badge badge-primary badge-sm position-absolute badge-top">{{ $store.state.unreadCount }}</span>
            </a>
            <ul
              class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4"
              :class="showMenuNotification ? 'show' : ''"
              aria-labelledby="dropdownMenuButtonNotifications"
            >
              <li v-if="!formattedNotifications.length" class="mb-2">
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        No notifications yet
                      </h6>
                    </div>
                  </div>
                </a>
              </li>
              <li v-for="(notification, index) in formattedNotifications" :key="index" class="mb-2" @click="redirectToPage(notification.meta, notification)">
                <a class="dropdown-item border-radius-md" :class="{'notification-dropdown-item': notification.is_read === 0}" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <img
                        src="../../assets/img/team-2.jpg"
                        class="avatar avatar-sm me-3"
                        alt="user image"
                      />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">{{ notification.message }}</span>
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        {{ notification.relativeTime }}
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li v-if="formattedNotifications.length > 0" @click="redirect('NotificationsPage')">
                <a class="dropdown-item border-radius-md text-center">
                  See More
                </a>
              </li>
              <li v-else class="mb-2">
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        No notifications yet
                      </h6>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </li>
          <li
            class="nav-item dropdown d-flex align-items-center"
            :class="isRTL ? 'ps-2 px-3' : 'pe-2 px-3'"
          >
            <a id="dropdownMenuButtonCart"
              href="#"
              class="p-0 nav-link position-relative"
              :class="[
                  textWhite ? textWhite : 'text-body',
                  showMenuCart ? 'show' : '',
              ]"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              @click="showMenuCart = !showMenuCart"
            >
              <i class="cursor-pointer fas fa-shopping-cart fa-lg"></i>
              <span class="badge badge-primary badge-sm position-absolute badge-top">{{ $store.state.marketplace.cartItems.length }}</span>
            </a>
            <ul
              class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4"
              :class="showMenuCart ? 'show' : ''"
              aria-labelledby="dropdownMenuButtonCart"
            >
              <li v-for="cartItem in $store.state.marketplace.cartItems" :key="cartItem.id" class="mb-2">
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div class="my-auto" @click="$router.push({name: isLoggedIn() ? 'CartPage' : 'PublicCartPage'})">
                      <img
                        :src="cartItem.productImages[0].image"
                        class="avatar avatar-sm bg-gradient-dark me-3"
                        alt="logo spotify"
                      />
                    </div>
                    <div class="d-flex flex-column justify-content-center" @click="$router.push({name: isLoggedIn() ? 'CartPage' : 'PublicCartPage'})">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">{{ isLoggedIn() ? cartItem.productName : cartItem.name }}</span>
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <!-- <i class="fa fa-clock me-1"></i> -->
                        Qty : {{ cartItem.cartQty }}
                      </p>
                      <p class="mb-0 text-xs text-secondary">
                        <!-- <i class="fa fa-clock me-1"></i> -->
                        Price per Unit : {{ "$" + cartItem.price }}
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li v-if="$store.state.marketplace.cartItems.length > 0" @click="$router.push({ name: isLoggedIn() ? 'CartPage' : 'PublicCartPage'})">
                <a class="dropdown-item border-radius-md text-center text-bold">
                  Total Price : {{ defaultCurrency.name + " " + calTotalPrice($store.state.marketplace.cartItems) }}
                </a>
              </li>
              <li v-if="$store.state.marketplace.cartItems.length > 0" @click="$router.push({ name: isLoggedIn() ? 'CartPage' : 'PublicCartPage'})">
                <a class="dropdown-item border-radius-md text-center">
                  See More
                </a>
              </li>
              <li v-else>
                <a class="dropdown-item border-radius-md text-center">
                  No items
                </a>
              </li>
            </ul>
          </li>
          <li class="px-3 nav-item d-flex align-items-center">
            <a
              class="p-0 nav-link"
              :class="textWhite ? textWhite : 'text-body'"
              @click="redirect('ContactUsPage')"
            >
              <i class="cursor-pointer fa fa-phone fixed-plugin-button-nav fa-lg"></i>
            </a>
          </li>
          <li
            class="nav-item dropdown d-flex align-items-center"
            :class="isRTL ? 'ps-2 px-3' : 'pe-2 px-3'"
          >
            <a
              v-if="isLoggedIn()"
              class="p-0 nav-link"
              :class="textWhite ? textWhite : 'text-body'"
              @click="logout()"
            >
              <i class="cursor-pointer fa fa-sign-out fa-lg"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapMutations, mapActions, mapState } from "vuex";
import utils from "../../utils.js";
import MarketplaceCartMixin from "../../mixins/MarketplaceCartMixin";
import NotificationsMixin from '../../mixins/NotificationsMixin';
import socket from '../../configs/socket';

export default {
  name: "Navbar",
  components: {
    Breadcrumbs,
  },
  mixins:[MarketplaceCartMixin, NotificationsMixin],
  props: {
    minNav: {
      type: Function,
      default: () => {},
    },
    textWhite: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      notifications: [],
      unreadCount: 0,
      showMenuCart: false,
      showMenuNotification: false,
      defaultCurrency: {
        id: 6,
        name: 'USD'
      }
    };
  },
  computed: {
    ...mapState(["isRTL"]),
    currentRouteName() {
      return this.$route.name;
    },
    formattedNotifications() {
      return this.$store.state.notifications.map(notification => ({
        ...notification,
        relativeTime: utils.formatRelativeTime(notification.created_at),
      }));
    },
    currentDirectory() {
      let dir = this.$route.path.split("/")[1];
      return dir.charAt(0).toUpperCase() + dir.slice(1);
    },
  },
  created() {
    this.minNav;
  },
  async mounted () {
    socket.connect()
    socket.on('newNotification', (notification) => {
      console.log('Received notification:', notification);
      notification.is_read = 0
      this.$store.state.notifications.unshift(notification);
      this.$store.state.unreadCount += 1;
    })

    if (this.isLoggedIn()) {
      const userId =  
      this.registerUserForSendNotifications(userId)
      this.loadUserNotifications()
      this.$store.state.marketplace.cartItems = []
      await this.getCartItems()
      
      this.$store.state.marketplace.cartItems = this.cartItemList
    }
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),

    async loadUserNotifications () {
      const userId = JSON.parse(utils.getLoggedUser()).id
      const notifications = await this.loadNotificationsByUser(userId,1,5)
      
      this.$store.state.notifications =  notifications.notifications.data
      this.$store.state.unreadCount = notifications.unread_count
    },

    redirectToPage (meta, notification) {
      if (!notification.is_read) {
        this.readNotification(notification.id)
        this.loadUserNotifications()
      }
      
      const metaData = JSON.parse(meta)
      if (metaData && metaData.post_id) {
        this.$router.push({name: 'GnomePostViewPage', params: { id: metaData.post_id } })
      }
    },
    handleRedirect (cartItem) {
      // Get protocol (http or https)
      const protocol = window.location.protocol;
      
      // Get hostname (domain name)
      const hostname = window.location.hostname;
      
      // Get port (if specified)
      const port = window.location.port ? `:${window.location.port}` : '';
      
      const carPageUrl = this.isLoggedIn() ? 'ecommerce/products/product-page/' : 'ecommerce/products/public-product/'

      // Construct the base URL
      const baseUrl = `${protocol}//${hostname}${port}/`
      
      const productUrl = this.isLoggedIn() ? baseUrl + carPageUrl + cartItem.productId : baseUrl + carPageUrl + cartItem.id

      window.location.href = productUrl;
    },

    toggleSidebar() {
      this.toggleSidebarColor("bg-white");
      this.navbarMinimize();
    },

    logout() {
      socket.disconnect()
      this.$store.dispatch('logOut')
      this.$router.push({ name: 'SignIn' })
    },

    redirect(pageName) {
      this.$router.push({name: pageName})
    },

    isLoggedIn () {
      return utils.isLoggedIn()
    },

    calTotalPrice (items) {
      return items.reduce((total, item) => (item.cartQty * item.price) + total, 0)
    }
  },
};
</script>

<style scoped>
.badge-top {
   top: -10px;
   right: -20px;
}

.notification-dropdown-item {
  background-color: #f0f0f0;
}
</style>